<template>
    <order-list :countries="transporter.countries" ></order-list>
</template>
<script>


import OrderList from "@/views/OrderList";
// import {Drequest} from "@/plugins/Drequest";

export default {
  name: "Country",
  components: {OrderList},
  props: ["user"],
  data: () => ({
    transporter: {},
  }),
  mounted() {
    //this.tab = this.items[0];
  }
}
</script>
